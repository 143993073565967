export const HSL_PIE_COLORS = [
  'hsl(211, 100%, 50%)',
  'hsl(211, 100%, 65%)',
  'hsl(211, 100%, 80%)',
  'hsl(211, 100%, 95%)',

  'hsl(258, 90%, 50%)',
  'hsl(258, 90%, 65%)',
  'hsl(258, 90%, 80%)',
  'hsl(258, 90%, 95%)',
] as const;
